import React, { useState, useCallback, useMemo } from "react";
import { ReactComponent as TacklLogo } from "./TacklLogo.svg";
import "./ROICalculator.css"; // Make sure to create this CSS file if it doesn't exist

const InputField = React.memo(({ label, name, value, onChange }) => {
  const displayValue = name.toLowerCase().includes('initial') || 
                       name.toLowerCase().includes('coordination') || 
                       name.toLowerCase().includes('followups') || 
                       name.toLowerCase().includes('review') 
                       ? `${value} minutes` 
                       : value;

  return (
    <div className="mb-4">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-blue-300 mb-1"
      >
        {label}
      </label>
      <input
        id={name}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        onBlur={(e) => {
          if (e.target.value && !isNaN(e.target.value)) {
            onChange({
              target: {
                name: e.target.name,
                value: `${parseInt(e.target.value, 10)}`
              }
            });
          }
        }}
        className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white"
      />
      {displayValue !== value && (
        <div className="mt-1 text-sm text-gray-400">{displayValue}</div>
      )}
    </div>
  );
});

const ROICalculator = () => {
  const initialInputs = {
    initialRequest: "30",
    initialContact: "30",
    coordinationTenants: "20",
    followUps: "30",
    reviewQuotes: "40",
    quotesPerProperty: "1",
    properties: "450",
    hourlyRate: "28.85",
    tacklPricePerDoor: "1",
    tacklAnnualSubscription: "1188",
    numberOfQuoteRequests: "450",
    tacklInitialRequest: "5",
    tacklInitialContact: "0",
    tacklCoordinationTenants: "0",
    tacklFollowUps: "0",
    tacklReviewQuotes: "5",
  };

  const [inputs, setInputs] = useState(initialInputs);
  const [activeTab, setActiveTab] = useState("current");

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setInputs(prev => ({
      ...prev,
      [name]: value.replace(/[^\d.-]/g, '') // Remove any non-numeric characters except decimal point and minus sign
    }));
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetInputs = useCallback(() => {
    setInputs(initialInputs);
  }, [initialInputs]);

  const calculateResults = useMemo(() => {
    const parseInput = (value) => parseFloat(value) || 0;

    const currentProcessTime =
      (parseInput(inputs.initialRequest) +
        parseInput(inputs.initialContact) +
        parseInput(inputs.coordinationTenants) +
        parseInput(inputs.followUps) +
        parseInput(inputs.reviewQuotes)) /
      60;
    const tacklProcessTime =
      (parseInput(inputs.tacklInitialRequest) +
        parseInput(inputs.tacklInitialContact) +
        parseInput(inputs.tacklCoordinationTenants) +
        parseInput(inputs.tacklFollowUps) +
        parseInput(inputs.tacklReviewQuotes)) /
      60;

    const annualCurrentCost =
      currentProcessTime *
      parseInput(inputs.hourlyRate) *
      parseInput(inputs.properties) *
      parseInput(inputs.quotesPerProperty);
    const annualTacklCost =
      tacklProcessTime *
        parseInput(inputs.hourlyRate) *
        parseInput(inputs.properties) *
        parseInput(inputs.quotesPerProperty) +
      parseInput(inputs.tacklAnnualSubscription);

    const annualSavings = annualCurrentCost - annualTacklCost;
    const timeSavingsPercentage =
      ((currentProcessTime - tacklProcessTime) / currentProcessTime) * 100;

    return {
      currentProcessTime: currentProcessTime.toFixed(2),
      tacklProcessTime: tacklProcessTime.toFixed(2),
      annualCurrentCost: annualCurrentCost.toFixed(2),
      annualTacklCost: annualTacklCost.toFixed(2),
      annualSavings: annualSavings.toFixed(2),
      timeSavingsPercentage: timeSavingsPercentage.toFixed(2),
    };
  }, [inputs]);

  const formatCurrency = useCallback((value) => {
    return parseFloat(value).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }, []);

  const parseInput = useCallback((value) => parseFloat(value) || 0, []);

  return (
    <div
      className="min-h-screen"
      style={{ backgroundColor: "#5FA1B3", color: "white" }}
    >
      <div className="container mx-auto max-w-6xl">
        <div
          className="flex justify-between items-center mb-6 pt-4"
          style={{ height: "auto" }}
        >
          <TacklLogo
            className="max-h-24 max-w-full rotate-logo" // Added rotate-logo class
            style={{ margin: "0 auto" }}
          />
          <button
            onClick={resetInputs}
            className="text-2xl"
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            🔄
          </button>
        </div>

        <div className="mb-6 flex justify-center">
          <button
            className={`px-6 py-2 mr-2 rounded-md ${activeTab === "current" ? "bg-blue-600" : "bg-gray-700"}`}
            onClick={() => setActiveTab("current")}
          >
            Current Process
          </button>
          <button
            className={`px-6 py-2 rounded-md ${activeTab === "tackl" ? "bg-blue-600" : "bg-gray-700"}`}
            onClick={() => setActiveTab("tackl")}
          >
            Tackl Process
          </button>
        </div>

        <div className="bg-gray-800 shadow-lg rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4 text-blue-300">
            {activeTab === "current" ? "Current Process" : "Tackl Process"}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              {activeTab === "current" ? (
                <>
                  <InputField
                    label="Initial Request or Walkthrough"
                    name="initialRequest"
                    value={inputs.initialRequest}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="Initial Contact with Contractors"
                    name="initialContact"
                    value={inputs.initialContact}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="Coordination with Tenants"
                    name="coordinationTenants"
                    value={inputs.coordinationTenants}
                    onChange={handleInputChange}
                  />
                </>
              ) : (
                <>
                  <InputField
                    label="Tackl Initial Request"
                    name="tacklInitialRequest"
                    value={inputs.tacklInitialRequest}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="Tackl Initial Contact"
                    name="tacklInitialContact"
                    value={inputs.tacklInitialContact}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="Tackl Coordination with Tenants"
                    name="tacklCoordinationTenants"
                    value={inputs.tacklCoordinationTenants}
                    onChange={handleInputChange}
                  />
                </>
              )}
            </div>
            <div>
              {activeTab === "current" ? (
                <>
                  <InputField
                    label="Follow-ups with Contractors"
                    name="followUps"
                    value={inputs.followUps}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="Review or Compare Quotes"
                    name="reviewQuotes"
                    value={inputs.reviewQuotes}
                    onChange={handleInputChange}
                  />
                </>
              ) : (
                <>
                  <InputField
                    label="Tackl Follow-ups"
                    name="tacklFollowUps"
                    value={inputs.tacklFollowUps}
                    onChange={handleInputChange}
                  />
                  <InputField
                    label="Tackl Review Quotes"
                    name="tacklReviewQuotes"
                    value={inputs.tacklReviewQuotes}
                    onChange={handleInputChange}
                  />
                </>
              )}
            </div>
          </div>
          <div className="mt-4 p-4 bg-gray-700 rounded-md">
            <strong>Summary: </strong>
            {(() => {
              const totalMinutes = activeTab === "current"
                ? parseInput(inputs.initialRequest) + parseInput(inputs.initialContact) + parseInput(inputs.coordinationTenants) + parseInput(inputs.followUps) + parseInput(inputs.reviewQuotes)
                : parseInput(inputs.tacklInitialRequest) + parseInput(inputs.tacklInitialContact) + parseInput(inputs.tacklCoordinationTenants) + parseInput(inputs.tacklFollowUps) + parseInput(inputs.tacklReviewQuotes);
              
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;
              
              return `${totalMinutes} minutes (${hours} hours and ${minutes} minutes)`;
            })()}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-800 shadow-lg rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 text-blue-300">
              PM Company Details
            </h2>
            <InputField
              label="Quotes Requested Per Property"
              name="quotesPerProperty"
              value={inputs.quotesPerProperty}
              onChange={handleInputChange}
            />
            <InputField
              label="Number of Properties"
              name="properties"
              value={inputs.properties}
              onChange={handleInputChange}
            />
            <InputField
              label="Hourly Labor Rate (USD)"
              name="hourlyRate"
              value={inputs.hourlyRate}
              onChange={handleInputChange}
            />
          </div>

          <div className="bg-gray-800 shadow-lg rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 text-blue-300">
              Tackl Details
            </h2>
            <div className="mb-4">
              <label
                htmlFor="tacklPricePerDoor"
                className="block text-sm font-medium text-blue-300 mb-1"
              >
                Tackl Price Per Door
              </label>
              <input
                id="tacklPricePerDoor"
                type="text"
                value={`$${formatCurrency(parseFloat(inputs.tacklAnnualSubscription) / parseFloat(inputs.properties))}`}
                disabled
                className="w-full p-2 bg-gray-600 border border-gray-600 rounded-md text-gray-400 cursor-not-allowed"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="numberOfQuoteRequests"
                className="block text-sm font-medium text-blue-300 mb-1"
              >
                Number of Quote Requests
              </label>
              <input
                id="numberOfQuoteRequests"
                type="text"
                value="Unlimited"
                disabled
                className="w-full p-2 bg-gray-600 border border-gray-600 rounded-md text-gray-400 cursor-not-allowed"
              />
            </div>
            <InputField
              label="Tackl Annual Subscription"
              name="tacklAnnualSubscription"
              value={inputs.tacklAnnualSubscription}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="bg-blue-600 rounded-lg p-6">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-center">
            TACKL COULD SAVE YOU
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-center">
            <div className="mb-4 sm:mb-0">
              <p className="text-3xl sm:text-4xl font-bold">${formatCurrency(calculateResults.annualSavings)}</p>
              <p className="text-base sm:text-lg">Annual Cost Savings</p>
            </div>
            <div>
              <p className="text-3xl sm:text-4xl font-bold">
                {calculateResults.timeSavingsPercentage}%
              </p>
              <p className="text-base sm:text-lg">Annual Time Savings</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ROICalculator;
