import React from 'react';
import ROICalculator from './ROICalculator';
import './globals.css';

function App() {
  return (
    <div className="App">
      <ROICalculator />
    </div>
  );
}

export default App;